<template>
  <div
    class="ad-group-container padding-20 border-radius-6 box-shadow-light-grey"
  >
    <div class="operation-list padding-bottom-20">
      <div class="row">
        <exclude-query :params="params" style="margin-right: 5px" />
        <select-platform-channel :params="params" style="margin-right: 5px" />
        <el-select
          v-model="booksId"
          filterable
          remote
          clearable
          style="width: 150px; margin-right: 5px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="selected"
          clearable
          @clear="handleClear"
          placeholder="团队/用户"
          style="width: 150px; margin-right: 5px"
          @focus="chooseDeptOrUser"
        ></el-input>
        <select-dept-user
          :show="isShowDeptUser"
          @close="isShowDeptUser = false"
          @confirm="confirm"
        />
        <el-select
          v-model="isTest"
          clearable
          placeholder="类型"
          style="width: 100px; margin-right: 5px"
        >
          <el-option label="普通号" :value="0"></el-option>
          <el-option label="测书号" :value="1"></el-option>
        </el-select>
        <el-select
          v-model="params.labelType"
          clearable
          placeholder="标签"
          style="width: 100px"
        >
          <el-option label="腾讯" :value="1"></el-option>
          <el-option label="百度" :value="2"></el-option>
          <el-option label="头条" :value="3"></el-option>
          <el-option label="GDT" :value="4"></el-option>
        </el-select>
        <!-- 创意人员 -->
        <el-select
          v-model="params.ideaUserId"
          filterable
          clearable
          placeholder="创意人员"
          style="width: 120px; margin-left: 10px"
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 15px"
          >搜索</el-button
        >
        <el-button type="info" @click="handleReset">重置</el-button>
      </div>
      <!-- <div class="row">
        <el-input
          placeholder="计划ID或名称"
          clearable
          v-model="search"
          style="width: 200px"
          class="input-with-select"
          @keydown.enter.native="handlePageChange(1)"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handlePageChange(1)"
          ></el-button>
        </el-input>
      </div> -->
    </div>
    <div class="main-container">
      <div>
        <el-table
          v-loading="adLoading"
          :data="tableData"
          height="66.3vh"
          @sort-change="handleSortChange"
          style="width: 100%"
        >
          <el-table-column
            prop="channelName"
            fixed="left"
            label="公众号"
            width="70"
          >
          </el-table-column>
          <el-table-column prop="putName" fixed="left" label="投手" width="60">
            <template slot-scope="scope">
              <span class="operate-text" @click="handleUserClick(scope.row)">{{
                scope.row.putName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="left"
            prop="date"
            label="广告名称"
            min-width="150"
          >
            <template slot-scope="scope">
              <p class="line-tow">
                {{ scope.row.aname }}
              </p>
              <p class="tip-text adId" @click="handleadvertShow(scope.row, 1)">
                {{ scope.row.aid }}
                <!-- <img src="@/assets/img/baidu.png" alt="" /> -->
              </p>
              <span v-if="scope.row.labelType" class="label-type">
                <template v-if="scope.row.labelType === 1">
                  <img src="@/assets/img/qq.png" alt="" />
                </template>
                <template v-else-if="scope.row.labelType === 2">
                  <img src="@/assets/img/baidu.png" alt="" />
                </template>
                <template v-else-if="scope.row.labelType === 3">
                  <img
                    @click="handleShowRecordDetail(scope.row)"
                    style="cursor: pointer"
                    src="@/assets/img/toutiao.png"
                    alt=""
                  />
                </template>
                <template v-else-if="scope.row.labelType === 4">
                  <img src="@/assets/img/gdt.png" />
                </template>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="image"
            label="创意预览"
            align="center"
            width="100"
          >
            <template slot-scope="scope" v-if="scope.row.crtInfo">
              <el-popover
                v-if="scope.row.crtInfo"
                placement="right"
                width="330"
                trigger="hover"
              >
                <preview-advert
                  :desc="scope.row.desc"
                  :channel-name="scope.row.channelName"
                  :crt-info="scope.row.crtInfo"
                />

                <img
                  v-if="
                    JSON.parse(scope.row.crtInfo) &&
                    JSON.parse(scope.row.crtInfo).image
                  "
                  :src="JSON.parse(scope.row.crtInfo).image[0]"
                  @click="handleCrtInfo(scope.row)"
                  slot="reference"
                  alt=""
                  class="min-img"
                />
                <img
                  v-else-if="
                    JSON.parse(scope.row.crtInfo) &&
                    JSON.parse(scope.row.crtInfo).video
                  "
                  src="@/assets/img/video.png"
                  @click="handleCrtInfo(scope.row)"
                  slot="reference"
                  alt=""
                  class="min-img"
                  style="cursor: pointer"
                />
                <img
                  v-else
                  src="https://h5.file.yayawx.cn/novel_put/2020121750082.png"
                  @click="handleCrtInfo(scope.row)"
                  slot="reference"
                  alt=""
                  class="min-img"
                  style="cursor: pointer"
                />
                <!-- <span slot="reference">{{ 123 }}</span> -->
              </el-popover>
              <img :src="scope.row.image" v-else alt="" class="min-img" />
            </template>
          </el-table-column>
          <el-table-column prop="date" label="所属计划" min-width="160">
            <template slot-scope="scope">
              <p class="line-tow">{{ scope.row.cname }}</p>
              <p class="tip-text adId" @click="handleadvertShow(scope.row, 2)">
                {{ scope.row.cid }}
              </p>
            </template>
          </el-table-column>
          <!-- 新增创意人员 -->
          <el-table-column
            prop="ideaUserName"
            sortable="custom"
            label="创意人员"
            width="180"
          >
          </el-table-column>
          <el-table-column prop="putTime" label="投放时间" width="105">
            <template slot-scope="scope">
              {{ scope.row.beginTime }} ~ {{ scope.row.endTime }}
            </template>
          </el-table-column>
          <el-table-column
            prop="budget"
            sortable="custom"
            label="广告预算(元)"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="bid"
            sortable="custom"
            label="当前出价(元)"
            width="150"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="costAmount"
            label="花费(元)"
            width="100"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="expPv"
            label="曝光次数"
            width="120"
          >
          </el-table-column>
          <el-table-column
            width="120"
            sortable="custom"
            label="千次曝光成本"
            prop="thousandDisplayPrice"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="clkPv"
            label="点击次数"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="ctr"
            sortable="custom"
            label="点击率"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="convIndex"
            sortable="custom"
            label="转化目标量"
            width="150"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="convIndexCvr"
            label="目标转化率"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="orderPv"
            sortable="custom"
            label="下单量"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="orderRoi"
            sortable="custom"
            label="下单ROI"
            width="120"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="orderCost"
            label="下单成本(次数)"
            width="150"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="orderAmount"
            label="下单金额"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="score"
            sortable="custom"
            label="曝光评估"
            width="120"
          >
            <template slot-scope="scope">
              <process-circle v-if="scope.row.score" :score="scope.row.score" />
              <span v-else>暂无评估</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="followPv"
            sortable="custom"
            label="公众号关注次数"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="followRate"
            sortable="custom"
            label="公众号关注率"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="followCost"
            sortable="custom"
            label="公众号关注成本(次数)"
            width="200"
          >
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin: 10px 0; text-align: right"
          background
          @current-change="handlePageChange"
          :current-page="page"
          :page-size="20"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        <ad-recharge
          :show="showRecharge"
          @close="showRecharge = false"
          :account-channel-id="
            todayAdData ? todayAdData.accountChannelId : null
          "
          :channel-name="todayAdData ? todayAdData.channelName : null"
          :todayAdData="todayAdData"
        />
        <CreateInfo
          :show="showCreateInfo"
          :baiduId="baiduId"
          @close="showCreateInfo = false"
          :type="type"
        />
        <!-- 记录详情 -->
        <el-drawer
          title="记录详情"
          :visible.sync="showRecordDetail"
          size="1700px"
          append-to-body
        >
          <recordDetail
            v-if="showRecordDetail"
            :detailId="recordDetailId"
            :detailType="'plan'"
            @close="showRecordDetail = false"
          />
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
import recordDetail from "../../small-plane/batch-put/recordDetail.vue";
import { getAdBoardList } from "@/api/novelPut";
import { getBookList } from "@/api/account";
// import { getPutPlanData } from '@/api/statics'
import { platformList, pickerOptions } from "@/assets/js/options";
import excludeQuery from "@/views/statistics/components/excludeQuery";
import SelectPlatformChannel from "./../components/SelectPlatformChannel";
import SelectDeptUser from "./../components/SelectDeptUser";
import AdRecharge from "../todyAdRecharge";
import PreviewAdvert from "../preview";
import { mapGetters } from "vuex";
import CreateInfo from "@/components/todayAdCreateInfo";
export default {
  name: "adGroup",
  props: ["customParams"],
  data() {
    return {
      tableData: [],
      adLoading: true,
      options: [
        {
          label: "掌中云",
          value: 0,
        },
        {
          label: "阅文",
          value: 1,
        },
        {
          label: "追书云",
          value: 2,
        },
        {
          label: "阳光书城",
          value: 3,
        },
        {
          label: "悠书阁",
          value: 4,
        },
        {
          label: "网易",
          value: 5,
        },
      ],
      total: 0,
      pageSize: 15,
      page: 1,
      bookList: [],
      booksId: null,
      pickerOptions,
      originPlatform: null, // 平台
      adminUserId: null, // 用户ID
      deptId: null, // 部门ID
      accountChannelId: null, // 公众号
      ruledOutBooksIds: null, // 排除书籍ID
      ruledOutAccountChannelIds: null, // 排除公众号
      isTest: null, // 是否为册书号
      sortDesc: false, // 是否为倒序
      adInfoStatus: null,
      platformList,
      selected: "",
      isShowDeptUser: false,
      dataType: 1,
      search: null,
      // 今日广告相关
      sortName: null,
      showRecharge: false,
      todayAdData: {},
      params: {},
      showCreateInfo: false,
      baiduId: null,
      type: 2, // 默认百度
      // 记录详情
      showRecordDetail: false,
      recordDetailId: null,
    };
  },
  computed: {
    ...mapGetters(["userList"]),
  },
  created() {
    this.params = this.customParams.advert
      ? JSON.parse(JSON.stringify(this.customParams.advert))
      : {};
  },
  mounted() {
    this.initData();
    this.getBook(null);
  },
  methods: {
    // 展示记录详情
    // 今日广告在这里
    handleShowRecordDetail(row) {
      this.recordDetailId = row.aid;
      // this.recordDetailId = 1707768384364788;
      this.showRecordDetail = true;
    },
    // 创意预览展示
    handleCrtInfo(data) {
      if (data.labelType === 2 || data.labelType === 3) {
        if (data.labelType === 2) this.type = 2;
        if (data.labelType === 3) this.type = 3;
        this.baiduId = data.aid;
        this.showCreateInfo = true;
      }
    },
    // 今日广告相关------
    handleSortChange({ prop, order }) {
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.handlePageChange(1);
    },
    handleUserClick(item) {
      this.$set(this.params, "adminUserId", item.adminUserId);
      this.handlePageChange(1);
    },
    // 投放广告处理
    handleadvertShow(row, type) {
      this.todayAdData = row;
      this.todayAdData._type = type;
      this.showRecharge = true;
    },
    // 今日广告相关------
    initData() {
      this.adLoading = true;
      //   if (this.params.selectTime) {
      //     beginDat = this.params.selectTime[0];
      //     endDate = this.params.selectTime[1];
      //   } else {
      //     beginDate = new Date()
      //       .toLocaleString()
      //       .split("/")
      //       .map(item => item.padStart(2, "0"))
      //       .join("-");
      //     endDate = beginDate;
      //   }
      //   const duration =
      //     new Date(endDate + " 00:00:00").getTime() -
      //     new Date(beginDate + " 23:59:59").getTime();
      //   if (duration > 30 * 24 * 3600 * 1000) {
      //     this.$message.error("筛选时间不能超过一个月");
      //     return false;
      //   }
      //
      getAdBoardList({
        page: this.page,
        pageSize: 20,
        originPlatform: this.params.originPlatform,
        accountChannelId: this.params.accountChannelId,
        booksId: this.booksId,
        adminUserId: this.adminUserId,
        sortName: this.sortName,
        sortDesc: this.sortDesc,
        deptId: this.deptId,
        isTest: this.isTest,
        labelType: this.params.labelType,
        ideaUserId: this.params.ideaUserId,
        ruledOutBooksIds:
          this.params.ruledOutBooksIds && this.params.ruledOutBooksIds.length
            ? this.params.ruledOutBooksIds.map((item) => item.id).join()
            : null,
        ruledOutAccountChannelIds:
          this.params.ruledOutAccountChannelIds &&
          this.params.ruledOutAccountChannelIds.length
            ? this.params.ruledOutAccountChannelIds.join()
            : null,
      }).then((res) => {
        this.tableData = res.list.list;
        this.total = res.list.total;
        this.adLoading = false;
      });
    },
    handlePageChange(val) {
      this.page = val;
      this.initData();
    },
    handleClear() {
      this.$set(this.params, "adminUserId", null);
      this.$set(this.params, "deptId", null);
    },
    confirm(selected) {
      this.selected = selected.name;
      if (selected.type === 1) {
        this.$set(this.params, "adminUserId", selected.id);
      } else if (selected.type === 0) {
        this.$set(this.params, "deptId", selected.id);
      }
    },
    chooseDeptOrUser() {
      this.isShowDeptUser = true;
    },
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    handleReset() {
      this.originPlatform = null;
      this.accountChannelId = null;
      this.bookId = null;
      this.adminUserId = null;
      this.deptId = null;
      this.isTest = null;
      this.params.ruledOutBooksIds = [];
      this.params.ruledOutAccountChannelIds = [];
      this.params.labelType = null;
      this.search = null;
      this.dataType = 1;
      this.handlePageChange(1);
    },
  },
  components: {
    excludeQuery,
    SelectPlatformChannel,
    SelectDeptUser,
    AdRecharge,
    PreviewAdvert,
    CreateInfo,
    recordDetail,
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-pagination {
  text-align: right;
}
/deep/ .el-table {
  .is-leaf {
    border-bottom: 0px;
    height: 56px !important;
  }
}
.ad-group-container {
  background: #fff;
  .operation-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main-container {
    > div {
      box-sizing: border-box;
    }
  }
}
.min-img {
  width: 70px;
  height: 70px;
}
.tip-text {
  font-size: 12px;
  color: #999;
  line-height: normal;
  text-decoration: underline;
}

// 广告名称徽标
.label-type {
  font-size: 12px;
  vertical-align: text-top;
  img {
    margin-left: 5px;
    width: 10px;
    height: 10px;
  }
}
.label-type-1 {
  color: rgb(0, 191, 138);
}
.label-type-2 {
  color: rgb(253, 93, 90);
}
</style>
