<template>
  <el-drawer
    :title="`${checkType}：${channelName}`"
    size="1000px"
    :visible.sync="show"
    @open="handleInit"
    :before-close="() => $emit('close')"
  >
    <div class="currentPrice">当前出价:{{ currentPrice }}</div>
    <div class="adFilter">
      <el-date-picker
        v-model="adSelectTime"
        type="daterange"
        align="left"
        unlink-panels
        style="width: 240px"
        :clearable="false"
        range-separator="-"
        value-format="yyyy-MM-dd"
        @change="handlePageChange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      <el-radio-group v-model="dataType" @change="handlePageChange">
        <el-radio-button :label="1">分日查看</el-radio-button>
        <el-radio-button :label="2">分时查看</el-radio-button>
      </el-radio-group>
      <div class="selectData">
        <el-dropdown>
          <el-button type="default">
            <i class="spacedots spacedots-first"></i>
            {{ SelectArray[firstSelect]
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in SelectArray"
              :key="item.name"
              @click.native="firstSelectHandler(index)"
              >{{ item }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown>
          <el-button type="default">
            <i class="spacedots spacedots-last"></i>
            {{ SelectArray[secondSelect]
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in SelectArray"
              :key="item.name"
              @click.native="secondSelectHandler(index)"
              >{{ item }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="adGroup-cont">
      <div id="adGroup"></div>
      <el-table :data="dataList" height="50vh" style="width: 100%" show-summary>
        <el-table-column prop="date" label="时间" min-width="100">
        </el-table-column>
        <el-table-column prop="cost" label="花费(元)" min-width="100">
        </el-table-column>
        <el-table-column prop="viewCount" label="曝光量" min-width="80">
        </el-table-column>
        <el-table-column prop="validClickCount" label="点击量" min-width="80">
        </el-table-column>
        <el-table-column prop="ctrStr" label="点击率" min-width="80">
        </el-table-column>
        <el-table-column
          prop="conversionsCount"
          label="目标转化量"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="conversionsCost"
          label="目标转化成本(元)"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="conversionsRateStr"
          label="目标转化率"
          min-width="80"
        >
        </el-table-column>
        <el-table-column prop="orderRoi" label="下单ROI" min-width="80">
        </el-table-column>
        <el-table-column prop="orderAmount" label="下单金额" min-width="80">
        </el-table-column>
        <el-table-column
          prop="orderUnitPrice"
          label="下单客单价"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="thousandDisplayPrice"
          label="千次曝光成本"
          min-width="80"
        >
        </el-table-column>
      </el-table>
    </div>
  </el-drawer>
</template>

<script>
import { getadData, getCampaignData } from "@/api/statics";
import { formatTime } from "@/assets/js/utils";
import { pickerOptions } from "@/assets/js/options";
const echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/line");
require("echarts/lib/chart/bar");
require("echarts/lib/component/tooltip");
export default {
  name: "timeadGroup",
  props: {
    show: Boolean,
    accountChannelId: Number,
    channelName: String,
    queryDate: {
      type: String,
      default: formatTime(Date.now(), "yyyy-MM-dd"),
    },
    todayAdData: Object,
  },
  data() {
    return {
      dataType: 2,
      dataList: [],
      loading: false,
      myChart: null,
      activeTime: null,
      adSelectTime: null,
      pickerOptions,
      adData: {},
      firstSelect: 0,
      secondSelect: 0,
      SelectArray: [],
      currentPrice: "",
      checkType: "",
    };
  },
  created() {
    this.adSelectTime = [this.queryDate, this.queryDate];
  },
  watch: {
    firstSelect(newV, oldV) {
      this.setOption(this.adData);
    },
    secondSelect(newV, oldV) {
      this.setOption(this.adData);
    },
  },
  methods: {
    handleInit() {
      this.firstSelect = 0;
      this.secondSelect = 0;
      this.handlePageChange();
      this.$nextTick(() => {
        if (!this.myChart) {
          this.myChart = echarts.init(document.getElementById("adGroup"));
          window.onresize = this.myChart.resize;
        }
      });
    },
    handleTimeClick(data) {
      this.activeTime = data.time;
      this.setOption(data);
    },
    setOption(value) {
      const { date, graph } = value;
      const dateFilter = [];
      if (date) {
        graph.forEach((item) => {
          // item.type = this.dataType == 2 ? "line" : "bar";
          item.type = "line";
          item.smooth = true;
        });
        date.forEach((item) => {
          dateFilter.push(item.split(" ")[1] || item.split(" ")[0]);
        });
        this.myChart.setOption(
          {
            xAxis: {
              type: "category",
              data: dateFilter,
              boundaryGap: false,
            },
            yAxis: {
              type: "value",
              minInterval: 1,
            },
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "5%",
              right: "5%",
              bottom: "10%",
              top: "5%",
            },
            series: [graph[this.firstSelect], graph[this.secondSelect]],
          },
          true
        );
      } else {
        this.clearOption();
      }
    },
    handlePageChange() {
      this.currentPrice = this.todayAdData.bid;
      if (this.todayAdData._type === 1) {
        this.checkType = "投放广告";
      } else {
        this.checkType = "投放计划";
      }
      this.todayAdData._type === 1 ? this.adPageChange() : this.putIn();
    },
    firstSelectHandler(e) {
      this.firstSelect = e;
    },
    secondSelectHandler(e) {
      this.secondSelect = e;
    },
    // 由adid传入处理函数
    adPageChange() {
      getadData({
        beginDate: this.adSelectTime[0],
        endDate: this.adSelectTime[1],
        dataType: this.dataType,
        adgroupId: this.todayAdData.aid,
      }).then((res) => {
        if (res.graph) {
          this.adData = res;
          this.SelectArray = res.graph.map((item) => item.name);
          this.dataList = res.list;
          this.setOption(this.adData);
        } else {
          this.clearOption();
        }
      });
    },
    // 由投放id传入处理函数
    putIn() {
      getCampaignData({
        beginDate: this.adSelectTime[0],
        endDate: this.adSelectTime[1],
        dataType: this.dataType,
        campaignId: this.todayAdData.cid,
      }).then((res) => {
        if (res.graph) {
          this.adData = res;
          this.SelectArray = res.graph.map((item) => item.name);
          this.dataList = res.list;
          this.setOption(this.adData);
        } else {
          this.clearOption();
        }
      });
    },
    clearOption() {
      this.myChart.setOption(
        {
          xAxis: {
            type: "category",
            data: [],
            boundaryGap: false,
          },
          yAxis: {
            type: "value",
            minInterval: 0.01,
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: "5%",
            right: "5%",
            bottom: "10%",
            top: "5%",
            // containLabel: true
          },
          series: [],
          // series: [
          //   {
          //     data: graph,
          //     type: "line",
          //     smooth: true,
          //   },
          // ],
        },
        true
      );
    },
  },
  // 销毁echart不销毁会存在内存溢出
  destroyed() {
    if (this.myChart) {
      echarts.dispose(this.myChart);
    }
  },
};
</script>

<style scoped>
.adGroup-cont {
  padding: 0 20px;
  width: 100%;
}

#adGroup {
  width: 100%;
  height: 30vh;
  margin-bottom: 20px;
}
.adFilter {
  padding: 5px 10px;
}
.el-radio-group {
  margin-left: 10px;
}
.selectData {
  display: inline-block;
  margin-left: 150px;
}
.el-dropdown {
  margin-right: 5px;
}
.spacedots {
  display: inline-block;
  width: 5px;
  height: 5px;
}
.spacedots-first {
  background-color: #c23531;
}
.spacedots-last {
  background-color: #313c43;
}
.currentPrice {
  position: absolute;
  top: 52px;
  left: 22px;
  font-size: 14px;
}
</style>
